<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs2>
            <v-menu
              ref="menu1"
              v-model="beginDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedMonth"
                  :label="$t('global.month')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" type="month" color="primary" @input="beginDateMenu = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id" style="cursor:pointer" v-if="row.item.assignedShifts.length">
              <td class="text-xs-left no-wrap">
                {{ row.item.date | formattedDate }}
              </td>
              <td class="text-xs-left">
                <template v-for="(shift, i) in row.item.assignedShifts">
                  {{ shift.name }}
                </template>
              </td>
              <td class="text-xs-left no-wrap">
                <template>
                  <v-layout align-center v-for="(shift, i) in row.item.assignedShifts" :key="i">
                    <v-tooltip top v-if="shift.shiftType">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-icon>
                            {{
                              shift.shiftType === "FIX"
                                ? "mdi-clock-outline"
                                : shift.shiftType === "FLEXIBLE"
                                ? "mdi-timelapse"
                                : ""
                            }}
                          </v-icon>
                        </span>
                      </template>
                      <span class="font-weight-bold">{{ $t("shift_types." + shift.shiftType) }}</span>
                    </v-tooltip>
                    <span class="ml-2">{{ shift.beginTime }} - {{ shift.endTime }}</span>
                  </v-layout>
                </template>
              </td>
            </tr>
            <tr v-else class="grey lighten-4">
              <td class="text-xs-left no-wrap">
                {{ row.item.date | formattedDate }}
              </td>
              <td colspan="4">
                <template v-if="row.item.countAsOvertime">{{ $t("shift_types.HOLIDAY") }}</template>
                <template v-else>{{ $t("shift_types.OFF_DAY") }}</template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { SHIFT_PAGINATE } from "./../query";
  import TimesheetRow from "./../model/TimesheetRow";
  export default {
    name: "shifts",
    data: vm => ({
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "date",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("time.timesheet.date"),
            title: vm.$t("time.timesheet.date"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          /*   {
                           text: vm.$t("time.timesheet.status"),
                           title: vm.$t("time.timesheet.status"),
                           value: "",
                           align: "center",
                           sortable: false,
                           class: ["primary--text"],
                           width: ""
                       },*/
          {
            text: vm.$t("time.timesheet.shift_name"),
            title: vm.$t("time.timesheet.shift_name"),
            value: "shift.name",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.shift"),
            title: vm.$t("time.timesheet.shift"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: "200px"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employees = [this.$store.state.auth.user.id];
          this.fetchItems();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: SHIFT_PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = [];

              for (let i = 0; i < data.filterWithPaginateWorkplans.content.length; i++) {
                let currentWorkplan = data.filterWithPaginateWorkplans.content[i];
                let timesheetRowModel = new TimesheetRow();
                timesheetRowModel.id = currentWorkplan.id;
                timesheetRowModel.date = currentWorkplan.date;
                timesheetRowModel.assignedShifts = currentWorkplan.assignedShifts;
                timesheetRowModel.countAsOvertime = currentWorkplan.countAsOvertime;
                this.table.data.push(timesheetRowModel);
              }

              this.table.totalItems = data.filterWithPaginateWorkplans.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginateWorkplans.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
